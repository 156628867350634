import { SortDirection } from '@core/core.types';
import { Coupon } from './../../core.types';
import { CouponsListFilterSettings } from './coupons.state';
export class LoadCouponsAction {
  static readonly type = '[Coupons] LoadCoupons';
  constructor(
    public options: {
      pageToLoad?: number;
      orderBy?: string;
      orderDirection?: SortDirection;
      filter?: CouponsListFilterSettings;
    },
    public refresh = false
  ) {}
}

export class ClearCouponsStateAction {
  static type = '[Coupons] ClearCouponsState';
}

export class LoadCouponById {
  static type = '[Coupons] LoadCouponById';
  constructor(
    public couponId: string,
    public refresh = false,
    public prepend = false
  ) {}
}

export class UpdateCouponAction {
  static type = '[Coupons] UpdateCoupon';
  constructor(public payload: { coupon: Coupon }) {}
}

export class AddCouponAction {
  static type = '[Coupons] AddCoupon';
  constructor(public payload: Coupon) {}
}

export class DeleteCouponAction {
  static type = '[Coupons] DeleteCupon';
  constructor(public couponId: string) {}
}

export class RevokeCouponAction {
  static type = '[Coupons] RevokeCoupon';
  constructor(public coupon: Coupon) {}
}

export class SetCouponsById {
  static readonly type = '[Coupons] SetCouponsById';

  constructor(public coupons: Coupon | Coupon[]) {}
}

export class LoadCouponDomainsAction {
  static readonly type = '[Coupons] LoadCouponDomains';
}

export class LoadCouponDomainByIdAction {
  static readonly type = '[Coupons] LoadCouponDomainById';

  constructor(public id: string) {}
}

export class CreateCouponDomainAction {
  static readonly type = '[Coupons] CreateCouponDomain';

  constructor(public data: { domain: string; description: string }) {}
}

export class UpdateCouponDomainAction {
  static readonly type = '[Coupons] UpdateCouponDomain';

  constructor(
    public id: string,
    public data: { domain: string; description: string }
  ) {}
}

export class ActivateCouponDomainAction {
  static readonly type = '[Coupons] ActivateCouponDomain';

  constructor(public id: string) {}
}

export class DeactivateCouponDomainAction {
  static readonly type = '[Coupons] DeactivateCouponDomain';

  constructor(public id: string) {}
}

export class UpdateCouponDomains {
  static readonly type = '[Coupons] UpdateCouponDomains';

  constructor(
    public couponId: string,
    public newDomains: string[]
  ) {}
}

export class UpdateCouponProducts {
  static readonly type = '[Coupons] UpdateCouponProducts';

  constructor(
    public couponId: string,
    public newProducts: string[]
  ) {}
}

export class UpdateCouponBundles {
  static readonly type = '[Coupons] UpdateCouponBundles';

  constructor(
    public couponId: string,
    public newBundles: string[]
  ) {}
}
